import React, { useState } from "react";
import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import { useQueryBusinessEnrolment } from "../../Api/useQueryBusinessEnrolment";
import { useMarketplaceSelector } from "components/MarketplaceSelector";
import BusinessEnrolmentPageResult from "./businessEnrolmentPageResult";
import { BusinessUploadStep } from "components/WizardSteps/BusinessUploadStep";
import { MarketplaceSelectionStep } from "components/WizardSteps/MarketplaceSelectionStep";
import { ReviewStep } from "components/WizardSteps/ReviewStep";
import { Form, Header, SpaceBetween, Wizard } from "@amzn/awsui-components-react/polaris";
import { useBusinessIdsFileUploader } from "hooks/useFileProcessor";
import { TimeoutAlert } from "components/TimeoutAlert";
import { useResponseTimeout } from "hooks/useResponseTimeout";

interface WizardNavigationEvent {
  detail: {
    requestedStepIndex: number;
  };
}

const MAX_BUSINESS_IDS = 500;

const BusinessEnrolmentPage: React.FC = () => {
  const [businessIds, setBusinessIds] = useState<string>("");
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [validationError, setValidationError] = useState<string>("");
  const [marketplaceError, setMarketplaceError] = useState<string>("");

  const { marketplace, MarketplaceSelector } = useMarketplaceSelector(null);
  const { fetchBusinessEnrolment, isFetching, error, businessEnrolmentResponse } = useQueryBusinessEnrolment();

  const getBusinessIdList = () =>
    businessIds
      .split(/[\n,]/)
      .map((id) => id.trim())
      .filter((id) => id.length > 0);

  const getBusinessIdsValidation = (ids: string[]): string | null => {
    if (ids.length === 0) {
      return "Please, enter at least one Business ID";
    }
    if (ids.length > MAX_BUSINESS_IDS) {
      return `Maximum of ${MAX_BUSINESS_IDS} Business IDs allowed at once`;
    }
    return null;
  };

  const validateBusinessIds = (ids: string[]): boolean => {
    const error = getBusinessIdsValidation(ids);
    setValidationError(error ?? "");
    return error === null;
  };

  const handleBusinessIdsChanged = ({ detail }: { detail: { value: string } }) => {
    setBusinessIds(detail.value);
    validateBusinessIds(getBusinessIdList());
  };

  const handleBusinessEnrolmentButtonClicked = () => {
    const businessIdList = getBusinessIdList();
    if (validateBusinessIds(businessIdList)) {
      fetchBusinessEnrolment(businessIdList, marketplace);
    }
  };

  const { handleFileUpload } = useBusinessIdsFileUploader({
    onBusinessIdsLoaded: (processedIds) => {
      setBusinessIds(processedIds.join("\n"));
      validateBusinessIds(processedIds);
    },
    onError: setValidationError,
  });

  const handleWizardNavigation = ({ detail }: WizardNavigationEvent) => {
    if (detail.requestedStepIndex < activeStepIndex) {
      setActiveStepIndex(detail.requestedStepIndex);
      return;
    }

    if (activeStepIndex === 0 && !validateBusinessIds(getBusinessIdList())) {
      return;
    }

    if (activeStepIndex === 1) {
      if (!marketplace) {
        setMarketplaceError("Please, select a marketplace to proceed");
        return;
      }
      setMarketplaceError("");
    }

    setActiveStepIndex(detail.requestedStepIndex);
  };

  const { hasTimedOut } = useResponseTimeout({
    isLoading: isFetching,
  });

  const wizardSteps = [
    {
      title: "Business Upload",
      description: "Upload the Business IDs you would like to enrol",
      content: (
        <BusinessUploadStep
          businessIds={businessIds}
          validationError={validationError}
          handleBusinessIdsChanged={handleBusinessIdsChanged}
          handleFileUpload={handleFileUpload}
          getBusinessIdList={getBusinessIdList}
        />
      ),
    },
    {
      title: "Select Marketplace",
      description: "Select the marketplace for business enrolment",
      content: (
        <MarketplaceSelectionStep MarketplaceSelector={MarketplaceSelector} marketplaceError={marketplaceError} />
      ),
    },
    {
      title: "Review and Enrol",
      description: "Review before proceeding with enrolment",
      content: (
        <ReviewStep
          reviewItems={getBusinessIdList().map((id, index) => ({ id, index: index + 1 }))}
          marketplace={marketplace}
        />
      ),
    },
  ];

  return (
    <IBAConsoleLayout>
      <SpaceBetween direction="vertical" size="xxl">
        <Form
          header={
            <Header variant="h1" description="Enrol Multiple Businesses in IBA">
              Enrol Businesses
            </Header>
          }
          errorText={error}
        >
          <Wizard
            i18nStrings={{
              stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
              collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
              skipToButtonLabel: (targetStep) => `Skip to ${targetStep.title}`,
              cancelButton: "",
              previousButton: "Previous",
              nextButton: "Next",
              submitButton: isFetching ? "Enrolling..." : "Enrol",
              optional: "optional",
            }}
            onNavigate={handleWizardNavigation}
            onSubmit={handleBusinessEnrolmentButtonClicked}
            isLoadingNextStep={isFetching}
            activeStepIndex={activeStepIndex}
            steps={wizardSteps}
          />
        </Form>
        <TimeoutAlert isVisible={hasTimedOut} />
        {businessEnrolmentResponse && <BusinessEnrolmentPageResult response={businessEnrolmentResponse} />}
      </SpaceBetween>
    </IBAConsoleLayout>
  );
};

export default BusinessEnrolmentPage;
