import React from "react";
import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import { Form, Header, SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { BusinessWizard } from "components/WizardSteps/BusinessWizard";
import { TimeoutAlert } from "components/TimeoutAlert";
import { useWizardNavigation } from "hooks/useWizardNavigation";
import { useResponseTimeout } from "hooks/useResponseTimeout";

export interface WizardStep {
  title: string;
  description: string;
  content: React.ReactNode;
  validation?: () => boolean;
}

export interface BaseBusinessPageProps {
  title: string;
  description: string;
  operationType: "enrol" | "unenrol";

  wizardSteps: WizardStep[];
  validationSteps?: Array<{
    index: number;
    validate: () => boolean;
  }>;

  isFetching: boolean;
  error: string | null;
  onSubmit: () => void;

  response?: any;
  ResultComponent: React.ComponentType<{ response: any }>;
}

export const BaseBusinessPage: React.FC<BaseBusinessPageProps> = ({
  title,
  description,
  operationType,
  wizardSteps,
  validationSteps = [],
  isFetching,
  error,
  onSubmit,
  response,
  ResultComponent,
}) => {
  const { activeStepIndex, handleWizardNavigation } = useWizardNavigation(validationSteps);
  const { hasTimedOut } = useResponseTimeout({ isLoading: isFetching });

  return (
    <IBAConsoleLayout>
      <SpaceBetween direction="vertical" size="xxl">
        <Form
          header={
            <Header variant="h1" description={description}>
              {title}
            </Header>
          }
          errorText={error}
        >
          <BusinessWizard
            operationType={operationType}
            isFetching={isFetching}
            activeStepIndex={activeStepIndex}
            wizardSteps={wizardSteps}
            onNavigate={handleWizardNavigation}
            onSubmit={onSubmit}
          />
        </Form>

        <TimeoutAlert isVisible={hasTimedOut} />

        {response && <ResultComponent response={response} />}
      </SpaceBetween>
    </IBAConsoleLayout>
  );
};

export const isBaseBusinessPageProps = (props: any): props is BaseBusinessPageProps => {
  return (
    typeof props.title === "string" &&
    typeof props.description === "string" &&
    (props.operationType === "enrol" || props.operationType === "unenrol") &&
    Array.isArray(props.wizardSteps) &&
    typeof props.onSubmit === "function" &&
    typeof props.ResultComponent === "function"
  );
};

export const createDefaultValidationStep = (
  index: number,
  validateFn: () => boolean
): { index: number; validate: () => boolean } => ({
  index,
  validate: validateFn,
});
