import React from "react";
import { BaseReviewStep, ReviewItem } from "./BaseReviewStep";

interface ReviewUnenrolmentStepProps {
  reviewItems: ReviewItem[];
  optOutReason: string | null;
}

export const ReviewUnenrolmentStep: React.FC<ReviewUnenrolmentStepProps> = ({ reviewItems, optOutReason }) => (
  <BaseReviewStep
    reviewItems={reviewItems}
    contextInfo={{
      label: "Selected Reason",
      value: optOutReason,
    }}
  />
);
