import React, { useState } from "react";
import {
  Alert,
  Table,
  Box,
  SpaceBetween,
  Header,
  TextFilter,
  Pagination,
  Button,
} from "@amzn/awsui-components-react/polaris";
import { downloadBusinessOperationCSV } from "utils/csvExport";

export interface BaseBusinessResultProps {
  response: any;
  operationType: "enrol" | "unenrol";
  pageSize?: number;
}

const BaseBusinessResultPage: React.FC<BaseBusinessResultProps> = ({ response, operationType, pageSize = 10 }) => {
  if (!response) {
    return <Alert type="error">Invalid input or missing business information.</Alert>;
  }

  if ("authorized" in response) {
    return <Alert type="error">Access denied. You are not authorized to {operationType} businesses in IBA.</Alert>;
  }

  const businessOperationDetails =
    Array.isArray(response) && response.every((item) => "businessId" in item && "status" in item);

  if (!businessOperationDetails) {
    return <Alert type="error">Invalid response format.</Alert>;
  }

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteringText, setFilteringText] = useState("");

  const tableItems = response.map((business) => ({
    id: business.businessId,
    status: business.status,
    reason: business.reason,
  }));

  const filteredItems = tableItems.filter((item) => item.id.toLowerCase().includes(filteringText.toLowerCase()));

  const startIndex = (currentPageIndex - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredItems.length);
  const currentPageItems = filteredItems.slice(startIndex, endIndex);

  const handleFilterChange = ({ detail }: { detail: { filteringText: string } }) => {
    setFilteringText(detail.filteringText);
    setCurrentPageIndex(1);
  };

  const handleDownloadCSV = () => {
    downloadBusinessOperationCSV(tableItems, operationType);
  };

  const getAlertType = (status: string): "success" | "error" | "info" => {
    switch (status.toUpperCase()) {
      case operationType === "enrol" ? "ENROLLED" : "UNENROLLED":
        return "success";
      case "FAILED":
        return "error";
      default:
        return "info";
    }
  };

  const capitalizedOperation = operationType.charAt(0).toUpperCase() + operationType.slice(1);

  return (
    <SpaceBetween direction="vertical" size="l">
      <Header variant="h1" description={`Business ${capitalizedOperation}ment Results`}>
        {capitalizedOperation}ment Results
      </Header>
      <Table
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <TextFilter
                  filteringText={filteringText}
                  filteringPlaceholder="Search by Business ID"
                  filteringAriaLabel="Filter Business IDs"
                  onChange={handleFilterChange}
                />
                <Button
                  onClick={handleDownloadCSV}
                  disabled={tableItems.length === 0}
                  iconName="download"
                  variant="icon"
                  ariaLabel="Download CSV"
                />
              </SpaceBetween>
            }
          >
            Business {capitalizedOperation}ment Results
          </Header>
        }
        columnDefinitions={[
          {
            id: "businessId",
            header: "Business ID",
            cell: (item) => item.id,
            sortingField: "id",
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => (
              <Alert
                type={getAlertType(item.status)}
                header={item.status === "FAILED" ? `FAILED: ${item.reason || "Unknown reason"}` : item.status}
              />
            ),
            sortingField: "status",
          },
        ]}
        items={currentPageItems}
        loadingText="Loading results"
        trackBy="id"
        variant="stacked"
        stripedRows
        stickyHeader
        resizableColumns
        wrapLines
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <b>No results</b>
            <Box padding={{ bottom: "s" }} variant="p" color="text-body-secondary">
              {filteringText ? "No matches found" : `No business ${operationType}ment results to display.`}
            </Box>
          </Box>
        }
      />
      <Pagination
        currentPageIndex={currentPageIndex}
        onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
        pagesCount={Math.ceil(filteredItems.length / pageSize)}
      />
    </SpaceBetween>
  );
};

export default BaseBusinessResultPage;
