import * as XLSX from "xlsx";

/**
 * Processes Excel file containing BA IDs in first column.
 * Requirements:
 * - First sheet only
 * - BA IDs must be in column A
 * - Header row (optional) in row 1
 */

const BA_ID_HEADER = "BA ID";

export const handleExcelData = (data: Uint8Array): string[] => {
  const workbook = XLSX.read(data, { type: "array" });
  const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
  const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as unknown[][];

  return jsonData
    .slice(1)
    .map((row: unknown[]) => {
      const firstCell = row[0];
      return firstCell ? String(firstCell).trim() : "";
    })
    .filter((id) => id.length > 0 && id !== BA_ID_HEADER);
};

export const processFileContent = (content: string): string[] => {
  const lines = content.split(/\r?\n/);
  return lines
    .slice(1)
    .map((line) => {
      const columns = line.split(/[\t]+|\s{2,}/);
      return columns[0]?.trim() || "";
    })
    .filter((id) => id.length > 0 && id !== BA_ID_HEADER);
};
