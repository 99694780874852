import React, { useState } from "react";
import {
  BusinessEnrolmentDetails,
  NotAuthorizedResult,
  BusinessEnrolmentResponse,
} from "../../Types/BusinessEnrolmentTypes";
import {
  Alert,
  Table,
  Box,
  SpaceBetween,
  Header,
  TextFilter,
  Pagination,
  Button,
} from "@amzn/awsui-components-react/polaris";

const BusinessEnrolmentDetailPageResult: React.FC<{ response: BusinessEnrolmentResponse }> = ({ response }) => {
  if (!response) {
    return <Alert type="error">Invalid input or missing business information.</Alert>;
  }

  const isNotAuthorizedResult = (response: BusinessEnrolmentResponse): response is NotAuthorizedResult => {
    return "authorized" in response;
  };

  if (isNotAuthorizedResult(response)) {
    return <Alert type="error">Access denied. You are not authorized to enrol businesses into IBA.</Alert>;
  }

  const isBusinessEnrolmentDetails = (response: BusinessEnrolmentResponse): response is BusinessEnrolmentDetails => {
    return Array.isArray(response) && response.every((item) => "businessId" in item && "status" in item);
  };

  if (!isBusinessEnrolmentDetails(response)) {
    return <Alert type="error">Invalid response format.</Alert>;
  }

  const tableItems = response.map((business) => ({
    id: business.businessId,
    status: business.status,
    reason: business.reason,
  }));

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteringText, setFilteringText] = useState("");
  const pageSize = 10;

  const filteredItems = tableItems.filter((item) => item.id.toLowerCase().includes(filteringText.toLowerCase()));

  const startIndex = (currentPageIndex - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredItems.length);
  const currentPageItems = filteredItems.slice(startIndex, endIndex);

  const handleFilterChange = ({ detail }: { detail: { filteringText: string } }) => {
    setFilteringText(detail.filteringText);
    setCurrentPageIndex(1);
  };

  const handleDownloadCSV = () => {
    const headers = ["Business ID", "Status", "Reason"];
    const csvData = tableItems.map((item) => {
      return [item.id, item.status, item.status === "FAILED" ? item.reason || "Unknown reason" : ""].join(",");
    });

    const csvContent = [headers.join(","), ...csvData].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "business_enrolment_results.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <SpaceBetween direction="vertical" size="l">
      <Header variant="h1" description="Business Enrolment Results">
        Enrolment Results
      </Header>
      <Table
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <TextFilter
                  filteringText={filteringText}
                  filteringPlaceholder="Search by Business ID"
                  filteringAriaLabel="Filter Business IDs"
                  onChange={handleFilterChange}
                />
                <Button
                  onClick={handleDownloadCSV}
                  disabled={tableItems.length === 0}
                  iconName="download"
                  variant="icon"
                  ariaLabel="Download CSV"
                />
              </SpaceBetween>
            }
          >
            Business Enrolment Results
          </Header>
        }
        columnDefinitions={[
          {
            id: "businessId",
            header: "Business ID",
            cell: (item) => item.id,
            sortingField: "id",
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => (
              <Alert
                type={getAlertType(item.status)}
                header={item.status === "FAILED" ? `FAILED: ${item.reason || "Unknown reason"}` : item.status}
              ></Alert>
            ),
            sortingField: "status",
          },
        ]}
        items={currentPageItems}
        loadingText="Loading results"
        trackBy="id"
        variant="stacked"
        stripedRows
        stickyHeader
        resizableColumns
        wrapLines
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <b>No results</b>
            <Box padding={{ bottom: "s" }} variant="p" color="text-body-secondary">
              {filteringText ? "No matches found" : "No business enrolment results to display."}
            </Box>
          </Box>
        }
      />
      <Pagination
        currentPageIndex={currentPageIndex}
        onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
        pagesCount={Math.ceil(filteredItems.length / pageSize)}
      />
    </SpaceBetween>
  );
};

const getAlertType = (status: string): "success" | "error" | "info" => {
  switch (status.toUpperCase()) {
    case "ENROLLED":
      return "success";
    case "FAILED":
      return "error";
    default:
      return "info";
  }
};

export default BusinessEnrolmentDetailPageResult;
