import React from "react";
import { useQueryBusinessUnenrolment } from "../../Api/useQueryBusinessUnenrolment";
import { useOptOutReasonSelector } from "components/OptOutReasonSelector";
import { BusinessUploadStep } from "components/WizardSteps/BusinessUploadStep";
import { OptOutReasonSelectionStep } from "components/WizardSteps/OptOutReasonSelectionStep";
import { ReviewUnenrolmentStep } from "components/WizardSteps/ReviewUnenrolmentStep";
import { useBusinessIds } from "hooks/useBusinessIds";
import { BaseBusinessPage, WizardStep, createDefaultValidationStep } from "Pages/BaseBusinessPage";
import BusinessUnenrolmentPageResult from "../BusinessUnenrolmentPage/businessUnenrolmentPageResult";
import { useState } from "react";

const MAX_BUSINESS_IDS = 500;

const BusinessUnenrolmentPage: React.FC = () => {
  const {
    businessIds,
    setBusinessIds,
    validationError,
    setValidationError,
    getBusinessIdList,
    validateBusinessIds,
    handleBusinessIdsChanged,
  } = useBusinessIds(MAX_BUSINESS_IDS);

  const { optOutReason, OptOutReasonSelector } = useOptOutReasonSelector("");
  const [optOutReasonError, setOptOutReasonError] = useState<string>("");

  const { fetchBusinessUnenrolment, isFetching, error, businessUnenrolmentResponse } = useQueryBusinessUnenrolment();

  const validationSteps = [
    createDefaultValidationStep(0, () => validateBusinessIds(getBusinessIdList())),
    createDefaultValidationStep(1, () => {
      if (!optOutReason) {
        setOptOutReasonError("Please, include a reason to proceed");
        return false;
      }
      setOptOutReasonError("");
      return true;
    }),
  ];

  const wizardSteps: WizardStep[] = [
    {
      title: "Business Upload",
      description: "Upload the Business IDs you would like to unenrol",
      content: (
        <BusinessUploadStep
          businessIds={businessIds}
          validationError={validationError}
          handleBusinessIdsChanged={handleBusinessIdsChanged}
          getBusinessIdList={getBusinessIdList}
          onBusinessIdsLoaded={(processedIds) => {
            setBusinessIds(processedIds.join("\n"));
            validateBusinessIds(processedIds);
          }}
          onError={setValidationError}
        />
      ),
    },
    {
      title: "Select Reason",
      description: "Select the reason for business unenrolment",
      content: (
        <OptOutReasonSelectionStep OptOutReasonSelector={OptOutReasonSelector} optOutReasonError={optOutReasonError} />
      ),
    },
    {
      title: "Review and Unenrol",
      description: "Review before proceeding with unenrolment",
      content: (
        <ReviewUnenrolmentStep
          reviewItems={getBusinessIdList().map((id, index) => ({
            id,
            index: index + 1,
          }))}
          optOutReason={optOutReason}
        />
      ),
    },
  ];

  const handleSubmit = () => {
    const businessIdList = getBusinessIdList();
    if (validateBusinessIds(businessIdList)) {
      fetchBusinessUnenrolment(businessIdList, optOutReason);
    }
  };

  return (
    <BaseBusinessPage
      title="Unenrol Businesses"
      description="Unenrol Multiple Businesses in IBA"
      operationType="unenrol"
      wizardSteps={wizardSteps}
      validationSteps={validationSteps}
      isFetching={isFetching}
      error={error}
      onSubmit={handleSubmit}
      response={businessUnenrolmentResponse}
      ResultComponent={BusinessUnenrolmentPageResult}
    />
  );
};

export default BusinessUnenrolmentPage;
