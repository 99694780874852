interface BusinessItem {
  id: string;
  status: string;
  reason?: string;
}

export const downloadBusinessOperationCSV = (items: BusinessItem[], operationType: string): void => {
  const headers = ["Business ID", "Status", "Reason"];
  const csvData = items.map((item) => {
    return [item.id, item.status, item.status === "FAILED" ? item.reason || "Unknown reason" : ""].join(",");
  });

  const csvContent = [headers.join(","), ...csvData].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  try {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `business_${operationType}ment_results.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } finally {
    URL.revokeObjectURL(url);
  }
};
