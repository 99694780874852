import Select, { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import React from "react";

const REASONS = ["IBA Console"];

const useOptOutReasonSelector = (initialOptOutReason: string | null) => {
  const optOutReasonOptions = REASONS.map((optOutReason) => ({ label: optOutReason, value: optOutReason }));
  const initialOptOutReasonOption = optOutReasonOptions.find((option) => option.label === initialOptOutReason) ?? null;

  const [selectedOptOutReasonOption, setSelectedOptOutReasonOption] = React.useState<SelectProps.Option | null>(
    initialOptOutReasonOption
  );

  const handleSelectedOptOutReasonOptionChanged = ({ detail }: { detail: SelectProps.ChangeDetail }) =>
    setSelectedOptOutReasonOption(detail.selectedOption);

  const OptOutReasonSelector = () => (
    <Select
      selectedOption={selectedOptOutReasonOption}
      options={optOutReasonOptions}
      onChange={handleSelectedOptOutReasonOptionChanged}
    />
  );

  const optOutReason = selectedOptOutReasonOption?.label ?? "";

  return { optOutReason, OptOutReasonSelector };
};

export default useOptOutReasonSelector;
