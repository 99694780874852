import { useState } from "react";
import { useWebSocketApi } from "../hooks/useWebSocketApi";
import { BusinessEnrolmentDetails } from "../Types/BusinessEnrolmentTypes";

export function useQueryBusinessEnrolment() {
  const websocket = useWebSocketApi("businessEnrolment");
  const [businessEnrolmentResponse, setBusinessEnrolmentResponse] = useState<BusinessEnrolmentDetails>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchBusinessEnrolment = (businessIds: string[], marketplace: string) => {
    if (isFetching || !businessIds) return;

    setIsFetching(true);
    setError(null);

    websocket.connect({
      onOpen: () => {
        websocket.sendMessage({ inputId: businessIds, marketplace });
      },
      onMessage: (event) => {
        try {
          setBusinessEnrolmentResponse(JSON.parse(event.data));
        } catch (e) {
          setError("Failed to parse WebSocket message.");
        } finally {
          setIsFetching(false);
          websocket.closeConnection();
        }
      },
      onError: () => {
        setError("WebSocket error occurred.");
        setIsFetching(false);
        websocket.closeConnection();
      },
    });
  };

  return { fetchBusinessEnrolment, isFetching, error, businessEnrolmentResponse };
}
