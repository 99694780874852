import { handleExcelData, processFileContent } from "utils/fileProcessingUtils";

interface UseBusinessIdsFileUploaderProps {
  onBusinessIdsLoaded: (businessIds: string[]) => void;
  onError: (error: string) => void;
  onFilesChanged?: (files: File[]) => void;
}

export const useBusinessIdsFileUploader = ({
  onBusinessIdsLoaded,
  onError,
  onFilesChanged,
}: UseBusinessIdsFileUploaderProps) => {
  const ALLOWED_EXTENSIONS = ["csv", "txt", "xlsx"];

  const handleFileUpload = async ({ detail }: { detail: { value: File[] } }) => {
    onFilesChanged?.(detail.value);

    if (detail.value.length === 0) return;

    const file = detail.value[0];
    const fileExtension = file.name.toLowerCase().split(".").pop();

    if (!ALLOWED_EXTENSIONS.includes(fileExtension?.toLowerCase() ?? "")) {
      onError(".CSV, .TXT, or .XLSX files supported only");
      return;
    }

    try {
      if (fileExtension === "xlsx") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const processedIds = handleExcelData(data);
          onBusinessIdsLoaded(processedIds);
        };
        reader.readAsArrayBuffer(file);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result as string;
          const processedIds = processFileContent(text);
          onBusinessIdsLoaded(processedIds);
        };
        reader.readAsText(file, "UTF-8");
      }
    } catch (error) {
      onError("Error processing file");
    }
  };

  return { handleFileUpload };
};
