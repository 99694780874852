import { useState } from "react";

export const useBusinessIds = (maxBusinessIds: number) => {
  const [businessIds, setBusinessIds] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");

  const getBusinessIdList = () =>
    businessIds
      .split(/[\n,]/)
      .map((id) => id.trim())
      .filter((id) => id.length > 0);

  const getBusinessIdsValidation = (ids: string[]): string | null => {
    if (ids.length === 0) {
      return "Please, enter at least one Business ID";
    }
    if (ids.length > maxBusinessIds) {
      return `Maximum of ${maxBusinessIds} Business IDs allowed at once`;
    }
    return null;
  };

  const validateBusinessIds = (ids: string[]): boolean => {
    const error = getBusinessIdsValidation(ids);
    setValidationError(error ?? "");
    return error === null;
  };

  const handleBusinessIdsChanged = ({ detail }: { detail: { value: string } }) => {
    setBusinessIds(detail.value);
    validateBusinessIds(getBusinessIdList());
  };

  return {
    businessIds,
    setBusinessIds,
    validationError,
    setValidationError,
    getBusinessIdList,
    validateBusinessIds,
    handleBusinessIdsChanged,
  };
};
