import React from "react";
import { BaseReviewStep, ReviewItem } from "./BaseReviewStep";

interface ReviewEnrolmentStepProps {
  reviewItems: ReviewItem[];
  marketplace: string | null;
}

export const ReviewEnrolmentStep: React.FC<ReviewEnrolmentStepProps> = ({ reviewItems, marketplace }) => (
  <BaseReviewStep
    reviewItems={reviewItems}
    contextInfo={{
      label: "Selected Marketplace",
      value: marketplace,
    }}
  />
);
