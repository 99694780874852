import React, { useState } from "react";
import {
  Container,
  Header,
  ColumnLayout,
  Box,
  Table,
  SpaceBetween,
  Pagination,
  TextFilter,
} from "@amzn/awsui-components-react/polaris";

interface ReviewItem {
  id: string;
  index: number;
}

interface ReviewStepProps {
  reviewItems: ReviewItem[];
  marketplace: string | null;
}

export const ReviewStep: React.FC<ReviewStepProps> = ({ reviewItems, marketplace }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteringText, setFilteringText] = useState("");
  const pageSize = 10;

  const filteredItems = reviewItems.filter((item) => item.id.toLowerCase().includes(filteringText.toLowerCase()));

  const startIndex = (currentPageIndex - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredItems.length);
  const currentPageItems = filteredItems.slice(startIndex, endIndex);

  const handleFilterChange = ({ detail }: { detail: { filteringText: string } }) => {
    setFilteringText(detail.filteringText);
    setCurrentPageIndex(1);
  };

  return (
    <Container>
      <SpaceBetween size="l">
        <Header variant="h3">Business Information</Header>
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Total Businesses</Box>
            <Box variant="awsui-value-large">{filteredItems.length}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Selected Marketplace</Box>
            <Box variant="awsui-value-large">{marketplace || "—"}</Box>
          </div>
        </ColumnLayout>
        <Table
          header={
            <Header
              actions={
                <TextFilter
                  filteringText={filteringText}
                  filteringPlaceholder="Search by Business ID"
                  filteringAriaLabel="Filter Business IDs"
                  onChange={handleFilterChange}
                />
              }
            >
              Business IDs
            </Header>
          }
          columnDefinitions={[
            {
              id: "index",
              header: "#",
              cell: (item) => item.index,
              width: 70,
              sortingField: "index",
            },
            {
              id: "businessId",
              header: "Business ID",
              cell: (item) => item.id,
              sortingField: "id",
            },
          ]}
          items={currentPageItems}
          loadingText="Loading Business IDs"
          trackBy="index"
          variant="stacked"
          stripedRows
          stickyHeader
          resizableColumns
          wrapLines
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <b>No Business IDs</b>
              <Box padding={{ bottom: "s" }} variant="p" color="text-body-secondary">
                {filteringText ? "No matches found" : "No Business IDs have been entered."}
              </Box>
            </Box>
          }
        />
        <Pagination
          currentPageIndex={currentPageIndex}
          onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
          pagesCount={Math.ceil(filteredItems.length / pageSize)}
        />
      </SpaceBetween>
    </Container>
  );
};
