import React from "react";
import { Container, Header, FormField, SpaceBetween } from "@amzn/awsui-components-react/polaris";

interface OptOutReasonSelectionStepProps {
  OptOutReasonSelector: React.ComponentType;
  optOutReasonError: string;
}

export const OptOutReasonSelectionStep: React.FC<OptOutReasonSelectionStepProps> = ({
  OptOutReasonSelector,
  optOutReasonError,
}) => (
  <Container>
    <SpaceBetween direction="vertical" size="l">
      <Header variant="h3">Choose Reason</Header>
      <FormField errorText={optOutReasonError} stretch>
        <OptOutReasonSelector />
      </FormField>
    </SpaceBetween>
  </Container>
);
