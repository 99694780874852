import React, { useState } from "react";
import { useQueryBusinessEnrolment } from "../../Api/useQueryBusinessEnrolment";
import { useMarketplaceSelector } from "components/MarketplaceSelector";
import { BusinessUploadStep } from "components/WizardSteps/BusinessUploadStep";
import { MarketplaceSelectionStep } from "components/WizardSteps/MarketplaceSelectionStep";
import { ReviewEnrolmentStep } from "components/WizardSteps/ReviewEnrolmentStep";
import { useBusinessIds } from "hooks/useBusinessIds";
import { BaseBusinessPage, WizardStep, createDefaultValidationStep } from "Pages/BaseBusinessPage";
import BusinessEnrolmentPageResult from "../BusinessEnrolmentPage/businessEnrolmentPageResult";

const MAX_BUSINESS_IDS = 500;

const BusinessEnrolmentPage: React.FC = () => {
  const {
    businessIds,
    setBusinessIds,
    validationError,
    setValidationError,
    getBusinessIdList,
    validateBusinessIds,
    handleBusinessIdsChanged,
  } = useBusinessIds(MAX_BUSINESS_IDS);

  const { marketplace, MarketplaceSelector } = useMarketplaceSelector(null);
  const [marketplaceError, setMarketplaceError] = useState<string>("");

  const { fetchBusinessEnrolment, isFetching, error, businessEnrolmentResponse } = useQueryBusinessEnrolment();

  const validationSteps = [
    createDefaultValidationStep(0, () => validateBusinessIds(getBusinessIdList())),
    createDefaultValidationStep(1, () => {
      if (!marketplace) {
        setMarketplaceError("Please, select a marketplace to proceed");
        return false;
      }
      setMarketplaceError("");
      return true;
    }),
  ];

  const wizardSteps: WizardStep[] = [
    {
      title: "Business Upload",
      description: "Upload the Business IDs you would like to enrol",
      content: (
        <BusinessUploadStep
          businessIds={businessIds}
          validationError={validationError}
          handleBusinessIdsChanged={handleBusinessIdsChanged}
          getBusinessIdList={getBusinessIdList}
          onBusinessIdsLoaded={(processedIds) => {
            setBusinessIds(processedIds.join("\n"));
            validateBusinessIds(processedIds);
          }}
          onError={setValidationError}
        />
      ),
    },
    {
      title: "Select Marketplace",
      description: "Select the marketplace for business enrollment",
      content: (
        <MarketplaceSelectionStep MarketplaceSelector={MarketplaceSelector} marketplaceError={marketplaceError} />
      ),
    },
    {
      title: "Review and Enrol",
      description: "Review before proceeding with enrolment",
      content: (
        <ReviewEnrolmentStep
          reviewItems={getBusinessIdList().map((id, index) => ({
            id,
            index: index + 1,
          }))}
          marketplace={marketplace}
        />
      ),
    },
  ];

  const handleSubmit = () => {
    const businessIdList = getBusinessIdList();
    if (validateBusinessIds(businessIdList)) {
      fetchBusinessEnrolment(businessIdList, marketplace);
    }
  };

  return (
    <BaseBusinessPage
      title="Enrol Businesses"
      description="Enrol Multiple Businesses in IBA"
      operationType="enrol"
      wizardSteps={wizardSteps}
      validationSteps={validationSteps}
      isFetching={isFetching}
      error={error}
      onSubmit={handleSubmit}
      response={businessEnrolmentResponse}
      ResultComponent={BusinessEnrolmentPageResult}
    />
  );
};

export default BusinessEnrolmentPage;
