import Select, { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import React from "react";

const MARKETPLACES = ["United Kingdom", "France", "Germany", "Italy", "Spain"];
// TODO: Add "India": https://sim.amazon.com/issues/9095fd3c-9294-49f0-8bd0-23a2ddd94b07.

const useMarketplaceSelector = (initialMarketplace: string | null) => {
  const marketplaceOptions = MARKETPLACES.map((marketplace) => ({ label: marketplace, value: marketplace }));
  const initialMarketplaceOption = marketplaceOptions.find((option) => option.label === initialMarketplace) ?? null;

  const [selectedMarketplaceOption, setSelectedMarketplaceOption] = React.useState<SelectProps.Option | null>(
    initialMarketplaceOption
  );

  const handleSelectedMarketplaceOptionChanged = ({ detail }: { detail: SelectProps.ChangeDetail }) =>
    setSelectedMarketplaceOption(detail.selectedOption);

  const MarketplaceSelector = () => (
    <Select
      selectedOption={selectedMarketplaceOption}
      options={marketplaceOptions}
      onChange={handleSelectedMarketplaceOptionChanged}
    />
  );

  const marketplace = selectedMarketplaceOption?.label ?? "";

  return { marketplace, MarketplaceSelector };
};

export default useMarketplaceSelector;
