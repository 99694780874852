import React from "react";
import { Alert } from "@amzn/awsui-components-react/polaris";

interface TimeoutAlertProps {
  isVisible: boolean;
  message?: string;
}

export const TimeoutAlert: React.FC<TimeoutAlertProps> = ({
  isVisible,
  message = "The request is taking longer than expected.",
}) => {
  if (!isVisible) return null;

  return <Alert type="warning">{message}</Alert>;
};
