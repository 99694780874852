import React from "react";
import { Wizard } from "@amzn/awsui-components-react/polaris";

interface BusinessWizardProps {
  operationType: "enrol" | "unenrol";
  isFetching: boolean;
  activeStepIndex: number;
  wizardSteps: any[]; // You may want to type this more specifically
  onNavigate: (event: { detail: { requestedStepIndex: number } }) => void;
  onSubmit: () => void;
}

export const BusinessWizard: React.FC<BusinessWizardProps> = ({
  operationType,
  isFetching,
  activeStepIndex,
  wizardSteps,
  onNavigate,
  onSubmit,
}) => {
  const getSubmitButtonText = () => {
    const action = operationType === "enrol" ? "Enrolling" : "Unenrolling";
    const actionButton = operationType === "enrol" ? "Enrol" : "Unenrol";
    return isFetching ? `${action}...` : actionButton;
  };

  return (
    <Wizard
      i18nStrings={{
        stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
        skipToButtonLabel: (targetStep) => `Skip to ${targetStep.title}`,
        cancelButton: "",
        previousButton: "Previous",
        nextButton: "Next",
        submitButton: getSubmitButtonText(),
        optional: "optional",
      }}
      onNavigate={onNavigate}
      onSubmit={onSubmit}
      isLoadingNextStep={isFetching}
      activeStepIndex={activeStepIndex}
      steps={wizardSteps}
    />
  );
};
