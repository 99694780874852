import React from "react";
import { Container, Header, FormField, SpaceBetween } from "@amzn/awsui-components-react/polaris";

interface MarketplaceSelectionStepProps {
  MarketplaceSelector: React.ComponentType;
  marketplaceError: string;
}

export const MarketplaceSelectionStep: React.FC<MarketplaceSelectionStepProps> = ({
  MarketplaceSelector,
  marketplaceError,
}) => (
  <Container>
    <SpaceBetween direction="vertical" size="l">
      <Header variant="h3">Select Marketplace</Header>
      <FormField label="Marketplace" errorText={marketplaceError} stretch>
        <MarketplaceSelector />
      </FormField>
    </SpaceBetween>
  </Container>
);
