import React, { useState } from "react";
import {
  Container,
  Header,
  FormField,
  Textarea,
  ColumnLayout,
  FileDropzone,
  Box,
  FileInput,
  SpaceBetween,
  Badge,
} from "@amzn/awsui-components-react/polaris";

interface BusinessUploadStepProps {
  businessIds: string;
  validationError: string;
  handleBusinessIdsChanged: (event: { detail: { value: string } }) => void;
  handleFileUpload: (event: { detail: { value: File[] } }) => void;
  getBusinessIdList: () => string[];
}

export const BusinessUploadStep: React.FC<BusinessUploadStepProps> = ({
  businessIds,
  validationError,
  handleBusinessIdsChanged,
  handleFileUpload,
  getBusinessIdList,
}) => {
  const [uploadedFiles] = useState<File[]>([]);
  return (
    <Container>
      <SpaceBetween direction="vertical" size="l">
        <Header variant="h3">Upload Businesses</Header>
        <FormField errorText={validationError} stretch>
          <Textarea
            value={businessIds}
            onChange={handleBusinessIdsChanged}
            placeholder="Enter Business IDs (new lines or comma-separated)"
            autoFocus
          />
        </FormField>
        <ColumnLayout columns={1} variant="text-grid">
          <div>
            <SpaceBetween direction="vertical" size="m">
              <Header variant="h3">Or upload a file</Header>
              <FileDropzone onChange={handleFileUpload}>
                <SpaceBetween size="s" alignItems="center" direction="vertical">
                  <Box textAlign="center">
                    <SpaceBetween size="xxs" direction="vertical">
                      <Box variant="strong">Drop your file here or</Box>
                      <FileInput value={uploadedFiles} onChange={handleFileUpload} accept=".csv,.txt,.xlsx">
                        Choose file
                      </FileInput>
                      <Box color="text-body-secondary" fontSize="body-s">
                        (.CSV, .TXT, or .XLSX supported only)
                      </Box>
                    </SpaceBetween>
                  </Box>
                </SpaceBetween>
              </FileDropzone>
            </SpaceBetween>
          </div>
        </ColumnLayout>
        <Container>
          <SpaceBetween direction="horizontal" size="xs" alignItems="center">
            <Badge color="blue">{getBusinessIdList().length}</Badge>
            <Box variant="strong">Business IDs added</Box>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </Container>
  );
};
