import { useState, useEffect } from "react";

const DEFAULT_TIMEOUT_DURATION = 5000;

interface UseResponseTimeoutProps {
  isLoading: boolean;
  timeoutDuration?: number;
}

export const useResponseTimeout = ({
  isLoading,
  timeoutDuration = DEFAULT_TIMEOUT_DURATION,
}: UseResponseTimeoutProps) => {
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isLoading) {
      setHasTimedOut(false);
      timeoutId = setTimeout(() => {
        setHasTimedOut(true);
      }, timeoutDuration);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading, timeoutDuration]);

  return { hasTimedOut };
};
