import { useState } from "react";

interface WizardNavigationEvent {
  detail: {
    requestedStepIndex: number;
  };
}

interface ValidationStep {
  index: number;
  validate: () => boolean;
}

export const useWizardNavigation = (validationSteps: ValidationStep[]) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const handleWizardNavigation = ({ detail }: WizardNavigationEvent) => {
    if (detail.requestedStepIndex < activeStepIndex) {
      setActiveStepIndex(detail.requestedStepIndex);
      return;
    }

    const currentValidationStep = validationSteps.find((step) => step.index === activeStepIndex);
    if (currentValidationStep && !currentValidationStep.validate()) {
      return;
    }

    setActiveStepIndex(detail.requestedStepIndex);
  };

  return { activeStepIndex, setActiveStepIndex, handleWizardNavigation };
};
